<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-xs-12 col-lg-7 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('shipping') }}
				</div>
			</div>
			<div
				v-if="!$user.auth()"
				class="col-xs-12 col-lg-5 d-flex">
				<div
					class="h4"
					:class="['xs','sm', 'md'].includes(windowWidth) ? 'mb-2 text-left' : 'my-auto ml-auto text-right'">
					<div class="text-primary">
						<span class="font-weight-bold">{{ translate('sponsor') }}: </span>
						<span>{{ sponsor.name }} </span>
						<span>({{ translate('id_word') }}: {{ sponsor.id }})</span>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<template
						v-if="this.$user.auth()">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate(addAddress ? 'add_new_address' : 'select_your_address') }}
									</div>
								</div>
							</div>
							<div
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 address-list">
									<address-radio-button
										:address-data="addressData"
										:address-loading="addressLoading"
										:form-import="form"
										:hide-new-address="addAddress"
										:display-add-new-address="false"
										:borderless="true"
										@add-address="addressFormState" />
									<div
										v-if="!addAddress && !addressLoading"
										class="row">
										<div class="col-12">
											<hr class="mt-0">
										</div>
									</div>
									<div
										v-if="addAddress && !addressLoading"
										class="pt-4">
										<address-form
											:user-country="true"
											:countries="countries"
											:states="states"
											:form-import="form"
											:errors-import="addressErrors.errors"
											:prefix-errors="''"
											:display-cancel-button="false"
											:display-submit-button="false"
											:display-goback-button="false"
											:display-override-toggle="showOverrideValidation"
											:label-cancel-button="translate('see_address_list')" />
									</div>
								</div>
							</div>
							<div
								v-if="!addressLoading"
								:class="{'px-4': windowWidth === 'md', 'px-5': !['xs','md'].includes(windowWidth) }"
								class="row">
								<div
									:class="{'mb-3': ['xs', 'sm'].includes(windowWidth), 'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
									<button
										v-if="addAddress && hasAddressData"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('see_address_list') }}
									</button>
									<button
										v-else-if="!addAddress"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : 'pl-0'"
										class="btn btn-link b-4 float-left"
										@click="addressFormState">
										{{ translate('use_another_address') }}
									</button>
								</div>
								<div
									v-if="addAddress"
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
									<button
										:disabled="(!hasAddressData && !addAddress)"
										:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
										class="btn btn-outline-primary b-4 float-right"
										@click="newAddress(form)">
										{{ translate('save_address_button') }}
									</button>
								</div>
								<div
									v-if="typeof validationErrors[`${steps[$route.name].validation}.shipping_address.address_id`] !== 'undefined'"
									id="address"
									:class="{'px-0': !['xs'].includes(windowWidth) }"
									class="col-12">
									<b-alert
										variant="danger"
										show>
										<div
											v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.address_id`]"
											:key="error">
											<span
												v-text="error" />
											<br>
										</div>
									</b-alert>
								</div>
							</div>
						</li>
					</template>
					<form
						v-if="!this.$user.auth()"
						@submit.prevent="prepareForStorage()"
						@keydown="clearValidationError($event.target.name);"
						@change="handleAddressFormChange($event.target.name);">
						<li class="list-group-item">
							<div class="row">
								<div class="col-12">
									<div class="h4 my-2">
										{{ translate('address_information') }}
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.address`"
											:label="translate('address')"
											:setter-value="form.address"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.address`]"
											:required="true"
											type="text"
											@dataChanged="form.address = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.address2`"
											:label="translate('address2')"
											:setter-value="form.address2"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.address2`]"
											type="text"
											@dataChanged="form.address2 = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.postcode`"
											:label="translate('postcode')"
											:setter-value="form.postcode"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.postcode`]"
											:required="true"
											type="text"
											@dataChanged="form.postcode = $event" />
									</div>
								</div>
							</div>
							<div class="row m-3">
								<div class="col-12 col-md-4">
									<div class="form-group">
										<input-text
											:id="`${steps[$route.name].validation}.shipping_address.city`"
											:label="translate('city')"
											:setter-value="form.city"
											:errors="validationErrors[`${steps[$route.name].validation}.shipping_address.city`]"
											:required="true"
											type="text"
											@dataChanged="form.city = $event" />
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label :for="`${steps[$route.name].validation}.shipping_address.country_code`">{{ translate('country') }} <span class="text-danger">*</span></label>
										<select
											:id="`${steps[$route.name].validation}.shipping_address.country_code`"
											v-model="form.country_code"
											:name="`${steps[$route.name].validation}.shipping_address.country_code`"
											:disabled="!allowAnotherCountry"
											class="form-control">
											<option value="">
												{{ translate('select_country') }}
											</option>
											<template v-if="hasCountries">
												<option
													v-for="item in countries"
													:key="item.id"
													:value="item.attributes.code">
													{{ translate(item.attributes.code.toLowerCase()) }}
												</option>
											</template>
										</select>
										<template v-if="typeof validationErrors[`${steps[$route.name].validation}.shipping_address.country_code`] !== 'undefined'">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.country_code`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
								<div class="col-12 col-md-4">
									<div class="form-group">
										<label for="countries">{{ translate('state') }} <span class="text-danger">*</span></label>
										<select
											id="state"
											v-model="form.region_id"
											:name="`${steps[$route.name].validation}.shipping_address.region_id`"
											:class="typeof validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`] !== 'undefined' ? 'is-invalid' : ''"
											class="form-control">
											<option value="">
												{{ translate('select_state') }}
											</option>
											<template v-if="hasState">
												<option
													v-for="item in states"
													:key="item.id"
													:value="item.id">
													{{ item.attributes.name }}
												</option>
											</template>
										</select>
										<template v-if="typeof validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`] !== 'undefined'">
											<span
												v-for="error in validationErrors[`${steps[$route.name].validation}.shipping_address.region_id`]"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger"
												v-text="error" />
										</template>
									</div>
								</div>
							</div>
							<!-- <div
								v-if="countriesListForSignature.includes(country)"
								class="row m-3">
								<div class="col-12">
									<switch-toggle
										id="signature_required"
										v-model="form.signature_required"
										name="signature_required"
										variant="success"
										class="mr-2"
										pill />
									<label for="signature_required">{{ translate('signature_label') }} <span class="text-danger">*</span></label>
								</div>
								<div class="col-12">
									<small v-html="translate('signature_disclaimer')" />
								</div>
							</div> -->
							<div
								v-if="showOverrideValidation"
								class="row m-3">
								<div class="col-12">
									<switch-toggle
										id="override_validation"
										v-model="form.override_validation"
										name="override_validation"
										variant="success"
										class="mr-2"
										pill />
									<label for="override_validation">
										{{ translate('override_validation') }}
										<span
											v-b-tooltip.hover
											:title="translate('override_validation_info')"
											class="text-muted">
											<i class="fas fa-lg fa-info-circle" />
										</span>
									</label>
								</div>
							</div>
						</li>
					</form>
					<li
						v-if="!loadingCartInfo && Object.keys(cartInfo.tickets).length"
						class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3">
								<div class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('event_attendees') }}</span>
										</div>
									</div>
									<template v-if="tickets">
										<div
											v-for="(item, key) in tickets"
											:key="key"
											:class="windowWidth === 'xs' ? '': (windowWidth === 'md' ? 'px-4' : 'px-5' )"
											class="col-12 mt-3">
											<div class="font-weight-bold">
												<span class="h5 align-top">{{ translate(item.code_name) }}</span>
											</div>
											<div
												v-for="(n, index) in item.qty"
												:key="index"
												class="row">
												<div class="col-12 col-md-6">
													<div class="form-group mb-0">
														<input-text
															:id="`${steps[$route.name].validation}.tickets.${key}.${index}.first_name`"
															:label="`${translate('attendee')} ${index+1}`"
															:placeholder="translate('first_name')"
															:setter-value="''"
															:errors="validationErrors[`${steps[$route.name].validation}.tickets.${key}.${index}.first_name`]"
															:required="true"
															type="text"
															@dataChanged="formatTicketsNames(key, index, 'first_name', $event, item.code_name)" />
													</div>
												</div>
												<div class="col-12 col-md-6">
													<div
														:class="{ 'mt-2': !['xs','sm'].includes(windowWidth) }"
														class="form-group mb-0">
														<input-text
															:id="`${steps[$route.name].validation}.tickets.${key}.${index}.last_name`"
															:placeholder="translate('last_name')"
															:setter-value="''"
															:errors="validationErrors[`${steps[$route.name].validation}.tickets.${key}.${index}.last_name`]"
															:required="false"
															type="text"
															@dataChanged="formatTicketsNames(key, index, 'last_name', $event, item.code_name)" />
													</div>
												</div>
											</div>
										</div>
									</template>
									<div
										v-if="ticketsError"
										:class="windowWidth === 'xs' ? '': (windowWidth === 'md' ? 'px-4' : 'px-5' )"
										class="col-12 mt-3">
										<b-alert
											variant="danger"
											show>
											<div
												v-for="error in validationErrors[`${steps[$route.name].validation}.tickets`]"
												:key="error">
												<span
													v-text="error" />
												<br>
											</div>
										</b-alert>
									</div>
								</div>
							</div>
						</div>
					</li>
					<li class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3">
								<div
									v-if="!loadingCartInfo"
									class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<span class="h4 align-top">{{ translate('select_shipping_method') }}</span>
										</div>
									</div>
									<template v-if="!loadingShippingMethods || cartInfo.is_virtual">
										<div class="col-12 mt-3">
											<b-alert
												v-if="(!fullAddressInForm && !hasAddressId) || cartInfo.is_virtual"
												:variant="cartInfo.is_virtual ? 'info' : 'warning'"
												show>
												{{ translate(cartInfo.is_virtual ? 'shipping_unnecessary' : 'incomplete_address_information') }}
											</b-alert>
											<b-alert
												v-else-if="!hasShippingMethods"
												variant="warning"
												show>
												{{ translate('no_address_found') }}
											</b-alert>
										</div>
										<div
											v-if="hasShippingMethods && (fullAddressInForm || hasAddressId)"
											:class="windowWidth === 'xs' ? '': (windowWidth === 'md' ? 'px-4' : 'px-5' )"
											class="col-12 col-md-6 mt-3">
											<div
												v-for="({ attributes: item}, index) in shippingMethods"
												:key="`${item.carrier_code_name}_${item.method_code_name}`"
												class="form-check my-2">
												<input
													:id="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
													v-model="shippingMethod"
													:value="{carrier_code_name: item.carrier_code_name, method_code_name: item.method_code_name}"
													:checked="index === 0"
													class="form-check-input"
													type="radio"
													name="shipping_method">
												<label
													:for="`${item.carrier_code_name}_${item.method_code_name}_${index}`"
													class="form-check-label">
													{{ translate(`${ item.carrier_code_name }_${ item.method_code_name }`) }}
												</label>
												<div class="float-right">
													{{ item.amount }}
												</div>
											</div>
										</div>
									</template>
									<is-loading
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										:loading="loadingShippingMethods"
										:has-data="true"
										class="mx-auto mt-4" />
								</div>
							</div>
						</div>
					</li>
					<li
						v-if="customer.includes(path) && !this.$user.auth()"
						class="list-group-item py-0 px-3 px-md-4">
						<div class="row">
							<div class="col-12 py-3 autoship-shipping">
								<div class="row">
									<div class="col-12">
										<div class="font-weight-bold">
											<div class="custom-control custom-checkbox my-auto">
												<input
													id="discount"
													v-model="addDiscount"
													type="checkbox"
													class="custom-control-input">
												<label
													for="discount"
													class="custom-control-label h4">
													{{ translate('autoship_discount') }}
												</label>
											</div>
										</div>
										<div class="text-smaller my-2">
											<b-alert
												variant="info"
												class="m-0"
												show
												v-html="translate('autoship_description_checkout')" />
										</div>
									</div>
									<template v-if="!loadingProducts && addDiscount">
										<div
											v-if="hasProducts && productsReadyAutoship"
											:class="windowWidth === 'xs' ? '' : 'px-3'"
											class="col-12 mt-3">
											<div class="row">
												<div
													v-for="item in products"
													:key="item.id"
													class="col-12 col-md-6 col-lg-4">
													<div
														v-if="item.attributes.available_for_autoship"
														class="row">
														<div
															style="min-height: 3.25em"
															class="col col-sm col-lg-5 d-flex my-auto align-items-center">
															<label
																:for="item.attributes.sku"
																class="mb-0">{{ translate(item.attributes.code_name) }}</label>
														</div>
														<div class="col align-self-center my-auto">
															<spinner
																v-model="autoshipProducts[item.attributes.sku].value"
																:min="0"
																:max="9999"
																:value="autoshipProducts[item.attributes.sku].value"
																:button-class="'vnis__button bg-primary-alt'"
																:input-class="'vnis__input vnis-input-custom-width'"
																:integer-only="true"
																:mouse-down-speed="500"
																role="button"
																class="vnis-smaller"
																style="min-width:106px;max-width:120px" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
									<is-loading
										v-if="addDiscount"
										:loading-label="translate('loading')"
										:no-data-label="translate('data_not_found')"
										:loading="loadingProducts"
										:has-data="true"
										class="mx-auto mt-4" />
								</div>
								<template v-if="typeof validationErrors[`${steps[$route.name].validation}.autoship.products`] !== 'undefined'">
									<b-alert
										class="mt-3"
										variant="warning"
										show>
										<span
											v-for="error in validationErrors[`${steps[$route.name].validation}.autoship.products`]"
											:key="error">
											<i class="fa fa-exclamation-circle" />
											{{ error }} <br>
										</span>
									</b-alert>
								</template>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 ml-auto my-4 text-right">
				<div
					v-if="customer.includes(path) && addDiscount"
					class="row">
					<div class="col-12 mb-4 text-left">
						<div class="form-check">
							<input
								id="autoshipAgreement"
								v-model="autoshipAgreement"
								name="autoshipAgreement"
								class="form-check-input"
								type="checkbox">
							<label
								class="form-check-label"
								for="autoshipAgreement"
								v-html="translate('autoship_agreement_checkout')" />
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-12 ml-auto">
						<div
							class="btn-group w-100"
							role="group">
							<b-button
								v-if="!$user.auth()"
								:disabled="!!preparing"
								variant="light"
								size="lg"
								@click="nextStep(true)">
								{{ translate('previous') }}
							</b-button>
							<b-button
								:disabled="validating || !!preparing || loadingProducts || loadingShippingMethods || (customer.includes(path) && !autoshipAgreement && addDiscount) || addAddress"
								variant="primary"
								size="lg"
								@click="prepareForStorage()">
								<i
									v-if="validating"
									class="fas fa-spinner fa-spin mr-2" />
								{{ translate('continue') }}
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Spinner from 'vue-number-input-spinner';
import { ALLOW_CHANGE_COUNTRY_TO_USER as allowAnotherCountry } from '@/settings/AddressBook';
import { UNPROCESSABLE, SYSTEM_ALERT } from '@/settings/Errors';
import { SORTED_PRODUCT_SKUS as sortList } from '@/settings/Products';
import {
	SHOW_SIGNATURES_BY_COUNTRY as countriesListForSignature,
	ADDRESS_VERIFICATION_COUNTRIES as addressVerificationCountries,
	SHIPPING_UPDATABLE_FIELDS as shippingUpdatableFields,
} from '@/settings/Shipping';
import { AUTOSHIP_MIN_QTY } from '@/settings/Wizard';
import { customer } from '@/settings/Roles';
import WindowSizes from '@/mixins/WindowSizes';
import AddressRadioButton from '@/components/AddressRadioButton';
import AddressForm from '@/components/AddressForm';
import switchToggle from '@/components/Switch';
import InputText from '@/components/InputText';
import IsLoading from '@/components/Loading';
import AddressBook from '@/util/AddressBook';
import Cart from '@/util/Cart';
import Country from '@/util/Country';
import Products from '@/util/Products';
import CommonMix from './mixins/Common';

const { CancelToken } = require('axios');

Spinner.watch.value = function changeValue(val) {
	this.numericValue = val;
};

export default {
	name: 'CheckoutOldShipping',
	components: {
		switchToggle,
		InputText,
		Spinner,
		IsLoading,
		AddressRadioButton,
		AddressForm,
	},
	mixins: [CommonMix, WindowSizes],
	data() {
		return {
			countryData: new Country(),
			stateData: new Country(),
			prodData: new Products(),
			shippingData: new Cart(),
			cartData: new Cart(),
			shippingMethod: {},
			timing: null,
			path: '',
			customer,
			sponsor: {
				id: '',
				name: '',
			},
			identityId: null,
			form: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				country_code: '',
				region_id: '',
				signature_required: false,
				override_validation: false,
				address_id: '',
			},
			autoshipAgreement: false,
			allowAnotherCountry,
			alert: new this.$Alert(),
			getSuggestion: new AddressBook(),
			countriesListForSignature,
			WindowSizes,
			productsReadyAutoship: false,
			autoshipProducts: {},
			showOverrideValidation: false,
			canLeave: false,
			addressVerificationCountries,
			autoshipMinQty: AUTOSHIP_MIN_QTY,
			cancel: () => null,
			shippingUpdatableFields,
			addDiscount: false,
			addressBook: new AddressBook(),
			createAddress: new AddressBook(),
			loadAddress: new AddressBook(),
			addAddress: false,
			hasAddressId: false,
			ticketsNames: {},
			onlyTicketsNames: {},
			ticketsError: false,
		};
	},
	computed: {
		products() {
			try {
				const productsData = this.prodData.data.response.data.data;
				productsData.sort((a, b) => {
					const aIndex = sortList.indexOf(a.attributes.sku);
					const bIndex = sortList.indexOf(b.attributes.sku);

					if (aIndex === -1) return 1;
					if (bIndex === -1) return -1;

					return aIndex - bIndex;
				});
				return productsData;
			} catch (error) {
				return [];
			}
		},
		loadingProducts() {
			try {
				return this.prodData.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasProducts() {
			return !!this.products.length;
		},
		countries() {
			try {
				const response = this.countryData.data.response.data.data;
				if (allowAnotherCountry === false) {
					return response.filter((item) => item.attributes.code === this.country);
				}
				return response;
			} catch (error) {
				return [];
			}
		},
		hasCountries() {
			return !!this.countries.length;
		},
		states() {
			try {
				return this.stateData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		hasState() {
			return !!this.states.length;
		},
		shippingStep() {
			return Object.keys(this.steps).find((item) => this.steps[item].validation === 'shipping');
		},
		savedDiscount() {
			const step = this.shippingStep;
			const { discount } = { ...this.getStepInformation(step) };
			return discount;
		},
		savedShipping() {
			const step = this.shippingStep;
			const { shipping } = { ...this.getStepInformation(step) };
			return shipping;
		},
		shippingMethods() {
			try {
				return this.shippingData.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		loadingShippingMethods() {
			try {
				return this.shippingData.data.loading;
			} catch (error) {
				return false;
			}
		},
		hasShippingMethods() {
			return !!this.shippingMethods.length;
		},
		fullAddressInForm() {
			const {
				address, country_code: country, postcode, region_id: state, city,
			} = this.form;
			if (address.trim().length && country.trim().length && postcode.trim().length && state > 0 && city.trim().length) {
				return true;
			}
			return false;
		},
		cartInfo() {
			try {
				return this.cartData.data.response.data.response;
			} catch (error) {
				return [];
			}
		},
		tickets() {
			try {
				return this.cartData.data.response.data.response.tickets;
			} catch (error) {
				return [];
			}
		},
		loadingCartInfo() {
			try {
				return this.cartData.data.loading;
			} catch (error) {
				return false;
			}
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		addressErrors() {
			return this.createAddress.data.errors;
		},
	},
	watch: {
		autoshipProducts(value) {
			this.createWatchesForProducts(value);
		},
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId(value) {
			this.clearValidationError('shipping.shipping_address.address_id');
			this.hasAddressId = false;
			if (value.length || !!value) {
				this.hasAddressId = true;
				this.setAddressInfo(value);
				this.getShippingMethods(true);
			}
		},
	},
	created() {
		this.countryData.getCountries();
		this.getGeneralInformation();
		if (this.customer.includes(this.path)) this.initializeAutoship();
		this.initializeValues();
		this.initializeAddress();
		this.getStoredInformation();
	},
	methods: {
		initializeAddress() {
			if (this.$user.auth()) {
				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (this.form.address_id.length === 0 && response.length) this.form.address_id = response[0].id;
				});
			}
		},
		setAddressInfo(addressId) {
			if (this.$user.auth()) {
				this.loadAddress.getAddress(this.$user.details().id, addressId).then((response) => {
					this.form.address_id = response.id;
				}).catch(() => {
					this.form.address_id = '';
					this.initializeAddress();
				});
			}
		},
		initializeValues() {
			if (this.$user.auth() === true) {
				this.form.first_name = this.$user.details().name;
				this.form.last_name = this.$user.details().last_name;
			}
			this.form.country_code = this.country;
			this.stateData.getStates(this.form.country_code);
		},
		initializeAutoship() {
			this.prodData.getProducts(this.getStoredCountry(), undefined, { isRegister: true }).then(() => {
				// if (typeof this.savedShipping.autoship !== 'undefined' && typeof this.savedShipping.autoship.products === 'object') {
				// 	this.autoshipEnabled = !!Object.keys(this.savedShipping.autoship.products).length;
				// }

				if (typeof this.savedShipping === 'undefined' || typeof this.savedShipping.autoship === 'undefined' || Object.keys(this.savedShipping.autoship.products).length === 0) {
					let remainingQty = this.autoshipMinQty[this.country];
					for (let index = 0; index < this.products.length; index += 1) {
						const currentProductSKU = this.products[index].attributes.sku;
						const currentProduct = this.autoshipProducts[currentProductSKU];

						if (remainingQty > 0) {
							this.$set(this.autoshipProducts, currentProductSKU, {
								value: currentProduct ? currentProduct.value += 1 : 1,
								required: true,
							});
							remainingQty -= 1;
							const nextIndex = index + 1;
							// When we increment the last product qty and there's remainingQty left,
							if (nextIndex >= this.products.length && remainingQty > 0) {
								// we 'reset' our accumulator, so we can go back to the 1st product
								index = -1;
							}
						} else {
							this.$set(this.autoshipProducts, currentProductSKU, {
								value: currentProduct ? currentProduct.value += 0 : 0,
								required: currentProduct ? currentProduct.required : false,
							});
						}
					}
				} else {
					this.products.forEach((item) => {
						if (typeof this.savedShipping.autoship.products[item.attributes.sku] !== 'undefined') {
							this.$set(this.autoshipProducts, item.attributes.sku, { value: this.savedShipping.autoship.products[item.attributes.sku], required: true });
						} else {
							this.$set(this.autoshipProducts, item.attributes.sku, { value: 0, required: false });
						}
					});
				}
			});
			return null;
		},
		getGeneralInformation() {
			const step = Object.keys(this.steps).find((item) => this.steps[item].validation === 'personal_information');
			const previous = this.getStepInformation(step);
			const {
				sponsor, personal_information: personalInformation, identity_id: identityId, path,
			} = previous;
			this.cartData.getCartInfo(this.$user.getCartId());
			if (typeof sponsor === 'undefined' || typeof personalInformation === 'undefined') {
				this.nextStep(true);
				return null;
			}
			this.path = path;
			this.identityId = identityId;
			this.getSponsorInfo(sponsor).then((response) => {
				Object.keys(this.sponsor).forEach((value) => {
					this.sponsor[value] = response[value];
				});
			});
			if (typeof personalInformation.first_name === 'string') {
				this.form.first_name = personalInformation.first_name;
			}
			if (typeof personalInformation.last_name === 'string') {
				this.form.last_name = personalInformation.last_name;
			}
			return null;
		},
		getStoredInformation() {
			try {
				const { shipping_address: data } = this.savedShipping;
				const { autoship } = this.savedShipping;
				if (!autoship) this.addDiscount = false;
				else this.addDiscount = this.savedDiscount;
				this.shippingMethod = this.savedShipping.shipping_method;
				const { country_code: countryCode } = data;
				if (this.$user.auth()) {
					Object.keys(this.form).forEach((key) => {
						if (typeof data[key] !== 'undefined') {
							this.form[key] = data[key];
							if (key === 'override_validation') {
								this.showOverrideValidation = true;
							}
						}
					});
				} else {
					Object.keys(this.form).forEach((key) => {
						if (typeof data[key] !== 'undefined' && !['first_name', 'last_name'].includes(key)) {
							this.form[key] = data[key];
							if (key === 'override_validation') {
								this.showOverrideValidation = true;
							}
						}
					});
				}
				if (countryCode !== this.country) {
					this.form.region_id = '';
				}
			} catch (error) {
				//
			}
			if (this.fullAddressInForm || this.hasAddressId) {
				this.getShippingMethods();
			}
			return null;
		},
		prepareForStorage() {
			this.preparing = true;
			const shipping = {
				shipping_address: { ...this.form },
				shipping_method: { carrier_code_name: null, method_code_name: null },
				tickets: this.onlyTicketsNames,
				tickets_info: this.ticketsNames,
			};
			if (this.hasAddressId) {
				shipping.shipping_address = { signature_required: this.form.signature_required, address_id: this.form.address_id };
			}
			if (this.addDiscount) {
				let autoship = {};
				if (Object.keys(this.autoshipProducts).length > 0 && this.path !== 'guest') {
					Object.keys(this.autoshipProducts).forEach((item) => {
						const product = {};
						if (this.autoshipProducts[item].required === true) {
							product[item] = this.autoshipProducts[item].value;
							autoship = { ...autoship, ...product };
						}
					});
				}
				if (Object.keys(autoship).length > 0) {
					shipping.autoship = { products: { ...autoship } };
				}
			}
			if (typeof this.shippingMethod !== 'undefined') {
				if (Object.keys(this.shippingMethod).length) {
					shipping.shipping_method = { ...this.shippingMethod };
				}
			}
			let payload = {
				step: this.steps[this.$route.name].validation, sponsor: this.sponsor.id, shipping, identity_id: this.identityId, path: this.path, discount: this.addDiscount,
			};
			if (!this.showOverrideValidation) {
				payload.shipping.shipping_address.override_validation = undefined;
			}
			this.validateStep(payload).then((response) => {
				payload = { ...payload, shipping_data: { ...response.response } };
				this.saveStep(payload);
			}).catch((errors) => {
				if (UNPROCESSABLE.includes(errors.status)) {
					const { cart_id: cartId, 'shipping.tickets': ticketsError } = errors.errors;
					if (typeof cartId !== 'undefined') {
						let response = '';
						cartId.forEach((item) => { response += `${item} \n`; });
						this.alert.toast('error', response, { timer: 6000 });
						setTimeout(() => {
							this.canLeave = true;
							this.$router.replace({ name: 'Store' });
						}, 6000);
					}
					if (typeof ticketsError !== 'undefined') {
						this.ticketsError = true;
					}

					Object.keys(errors.errors).forEach((errorKey) => {
						if (errorKey === 'shipping.shipping_address.suggestion') {
							this.getSuggestion.getSuggestion(this.form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								const options = {
									config: {
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.getShippingMethods();
								}).catch(() => {});
							});
						} else if (errorKey.includes('shipping.shipping_address.address') && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				this.preparing = false;
			});
			return null;
		},
		handleAddressFormChange(target) {
			if (target === 'shipping.shipping_address.country_code') {
				this.stateData.getStates(this.form.country_code).then(() => {
					this.form.region_id = '';
				});
			}
			this.clearValidationError(target);
			const targetValue = target.split('.').pop();
			if (this.shippingUpdatableFields.includes(targetValue)) this.getShippingMethods();
		},
		createWatchesForProducts(value) {
			Object.keys(value).forEach((item) => {
				this.$watch(() => this.autoshipProducts[item].required, (newVal) => {
					this.clearValidationError(`${this.steps[this.$route.name].validation}.autoship.products`);
					if (newVal) {
						if (this.autoshipProducts[item].value === 0) {
							this.autoshipProducts[item].value = 1;
						}
					} else {
						this.autoshipProducts[item].value = 0;
					}
				});
				this.$watch(() => this.autoshipProducts[item].value, (newVal) => {
					this.clearValidationError(`${this.steps[this.$route.name].validation}.autoship.products`);
					if (newVal > 0) {
						this.autoshipProducts[item].required = true;
					} else {
						this.autoshipProducts[item].required = false;
					}
				});
			});
			this.productsReadyAutoship = true;
		},
		getShippingMethods(hasAddressId = false) {
			this.clearValidationError('shipping.shipping_method.carrier_code_name');
			if ((!this.fullAddressInForm && !this.hasAddressId) || this.cartInfo.is_virtual) {
				return null;
			}
			if (this.timing) {
				clearTimeout(this.timing);
			}
			if (this.cancel) this.cancel();
			this.timing = setTimeout(() => {
				let payload = {};
				if (hasAddressId) {
					payload = { address_id: this.form.address_id };
				} else {
					payload = {
						first_name: this.form.first_name,
						last_name: this.form.last_name,
						address: this.form.address,
						address2: this.form.address2,
						postcode: this.form.postcode,
						city: this.form.city,
						country_code: this.form.country_code,
						region_id: this.form.region_id,
					};
				}
				this.shippingData.getShippingMethod(this.$user.getCartId(), payload, {
					cancelToken: new CancelToken((c) => { this.cancel = c; }),
				}).then((response) => {
					if (response.length === 0) {
						return null;
					}
					this.shippingMethod = !Object.keys(this.shippingMethod).length ? { carrier_code_name: response[0].attributes.carrier_code_name, method_code_name: response[0].attributes.method_code_name } : this.shippingMethod;
					return null;
				}).catch(() => {}).finally(() => {
					this.cancel = null;
				});
			}, 500);
			return null;
		},
		addressFormState() {
			if (!this.addAddress) {
				this.clearAddressForm();
			}
			if (Object.keys(this.addressErrors.errors).length > 0) {
				const errorFields = Object.keys(this.addressErrors.errors);
				errorFields.forEach((field) => {
					delete this.addressErrors.errors[field];
					this.addressErrors.errors = { ...this.addressErrors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
		clearAddressForm() {
			this.form.first_name = '';
			this.form.last_name = '';
			this.form.address = '';
			this.form.address2 = '';
			this.form.city = '';
			this.form.postcode = '';
			// this.form.country_code = '';
			this.form.region_id = '';
			this.form.override_validation = false;
			this.showOverrideValidation = false;
		},
		newAddress(form) {
			let options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);

			const payload = { ...form };
			if (!this.showOverrideValidation) {
				payload.override_validation = undefined;
			}

			this.createAddress.saveAddress(this.$user.details().id, payload).then(() => {
				this.alert.toast('success', this.translate('address_created'), { timer: 6000 });

				this.addressBook.getAddresses(this.$user.details().id).then((response) => {
					if (response.length) {
						const lastAddressPos = response.length - 1;
						this.form.address_id = response[lastAddressPos].id;
					}
				});

				this.addAddress = !this.addAddress;
			}).catch(() => {
				if (UNPROCESSABLE.includes(this.addressErrors.status)) {
					Object.keys(this.addressErrors.errors).forEach((errorKey) => {
						if (errorKey === 'suggestion') {
							this.getSuggestion.getSuggestion(form).then((suggestionResponse) => {
								let address = '';
								Object.keys(suggestionResponse.response.address).forEach((item, index) => {
									if (suggestionResponse.response.address[item] !== '' && item !== 'region_id') {
										address += `${suggestionResponse.response.address[item]}`;
										if (index < (Object.keys(suggestionResponse.response.address).length) - 2) {
											address += ', ';
										}
									}
								});
								const trans = {
									title: this.translate('invalid_address'),
									text: this.translate('suggestion_address', { address }),
								};
								options = {
									config: {
										icon: 'warning',
										showCancelButton: true,
										confirmButtonText: this.translate('accept_sugestion'),
										cancelButtonText: this.translate('manual_change'),
										reverseButtons: true,
										allowOutsideClick: false,
										allowEscapeKey: false,
									},
								};
								this.alert.confirmation(trans.title, trans.text, options, true).then(() => {
									Object.keys(suggestionResponse.response.address).forEach((suggestionItem) => {
										Object.keys(this.form).forEach((formItem) => {
											if (formItem === suggestionItem) {
												this.form[formItem] = suggestionResponse.response.address[suggestionItem];
											}
										});
									});
									this.newAddress(this.form);
								}).catch(() => {});
							}).catch(() => {});
						} else if (errorKey === 'address' && !!this.form.address.trim().length && this.addressVerificationCountries.includes(this.form.country_code)) {
							this.showOverrideValidation = true;
						}
					});
				}
				if (SYSTEM_ALERT.includes(this.addressErrors.status) || this.addressErrors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else {
					this.alert.close();
				}
			});
		},
		formatTicketsNames(sku, index, nameField, name, codeName) {
			this.ticketsError = false;
			if (typeof this.ticketsNames[sku] === 'undefined') {
				this.ticketsNames[sku] = {
					code_name: codeName,
					names: [],
				};
			}

			if (typeof this.ticketsNames[sku].names[index] === 'undefined') {
				this.ticketsNames[sku].names[index] = {
					first_name: '',
					last_name: '',
				};
			}

			this.ticketsNames[sku].names[index][nameField] = name;

			this.onlyTicketsNames[sku] = this.ticketsNames[sku].names;
		},
	},
	beforeRouteLeave(to, from, next) {
		if (to.name.includes('CheckoutOld')) {
			next();
			const alert = new this.$Alert();
			alert.close();
		} else if (to.name.includes('Store')) {
			next();
			if (this.canLeave) {
				const alert = new this.$Alert();
				alert.close();
				this.removeRegister();
			}
		}
	},
};
</script>
<style scoped>
	.text-smaller {
		font-size: 0.95em;
	}
</style>

<style>
	.address-list .card-body {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
</style>
